<template>
  <div class="about-view1-main">
    <div class="about-view1-content">
      <kcTitle style="margin-top: 60px" title="科创宣传" en="COMPANY PROFILE"></kcTitle>
      <div class="about-view1-content-video flex align-items-center justify-content-center">
        <kc-video :url="`https://futurefarm.oss-cn-hangzhou.aliyuncs.com/video/kcxd/20250208.mp4`" type="mp4" :is-live="false"
                  style="width: 80%"/>
      </div>
      <div class="flex flex-column align-items-center justify-content-center">
        <div class="about-view1-content-text" style="margin-top: 50px">
          青岛科创信达科技有限公司成立于2014年，致力于畜牧产业的数字化转型，以智能硬件为基础，通过软硬件相协同的形式，为企业信息化升级、数字化转型，提供整体解决方案，并提供产学研成果转化、政府项目申报、供应链金融等服务。
        </div>
        <div class="about-view1-content-text" style="margin-top: 30px; margin-bottom: 70px">
          经过多年的奋斗已经成国际领先的环控器研发厂商，建设了国内领先的智能硬件电磁实验室，目前链接智能终端已经超过了二十万台，自主研发的小科爱牧物联网平台已经累计服务上万家养殖场。
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import kcVideo from '../../../components/kcVideo'

export default {
  name: "view1",
  data() {
    return {
      host: process.env.VUE_APP_BASE_API.split(':')[0] + ':' + process.env.VUE_APP_BASE_API.split(':')[1],
    }
  },
  components: {
    kcVideo
  },
}
</script>

<style lang="scss" scoped>
.about-view1-main {
  display: flex;
  align-items: center;
  justify-content: center;

  .about-view1-content {
    min-width: 1200px;
    width: 1440px;
  }
}
.about-view1-content-video {
  margin-top: 80px;
  background: linear-gradient(white 25%, white 25%, #EEEEEE 25%, #EEEEEE 95%, white 95%, white);
}
.about-view1-content-text {
  width: 916px;
  font-size: 16px;
  font-weight: normal;
  color: #333333;
  line-height: 24px;
  text-align: center;
}
</style>
